.vbg-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: none;
    width: auto;
    height: 70px;
    min-height: 48px;
    min-width: 48px;
    background-image: url("../../images/icon_vbg.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    @media (min-width: 992px){
        background-image: none ;
    }

    img {
        width: auto;
        height: 100%;
        display: none;
        
        @media (min-width: 992px) {
            display: flex;
        }
    }
    // @media (max-width: 767px) {
    //     width: 50vw;
    //     min-width: 100px;
    //     max-width: 270px;
    //     svg {
    //         width: 80%;
    //         height: auto;
    //         padding: 0.5rem 0;
    //     }
    // }
    
}
