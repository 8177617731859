@font-face {
    font-family: DGUVMeta-Normal;
    src: url("../fonts/DGUVMeta-Normal.otf") format("opentype");
}

@font-face {
    font-family: DGUVMeta-Bold;
    src: url("../fonts/DGUVMeta-Bold.otf") format("opentype");
}

$font-family-sans-serif: DGUVMeta-Normal, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-family-bold: DGUVMeta-Bold, $font-family-sans-serif;
$font-family-base: $font-family-sans-serif;

@import "./DguvColors.sass";

$primary: $DGUV-Blau;
$secondary: $DGUV-Grau;
$success: $Gruen-D;
$info: $Blau-3;
$warning: $DGUV-Gelb;
$danger: $DGUV-Rot;
$light: #ffffff;
$dark: #1A1A1A;
$lightBlue: $Blau-10;