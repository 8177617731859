// Blau
$DGUV-Blau: #004994     // <18pt: AAA (weiß)
$Blau-2: #0063af
$Blau-3: #0075BF        // <18pt: AA (weiß) ; >18pt: AAA (weiß), AA (schwarz) 	 	 
$Blau-4: #0086cd        // <18pt: AA (schwarz) 	 	 
$DGUV-Cyan: #0095db     //	>18pt: AA (weiß)
$Blau-6: #009fe3        // <18pt: AAA (schwarz)	 	 	 
$Blau-7: #00b1eb	 	 	 
$Blau-8: #5bc5f2	 	 	 
$Blau-9: #a1daf8	 	 	 
$Blau-10: #d4edfc	 	

// Schwarz/Grau
$Schwarz: #000000	 	 	 
$Grau-1: #3c3c3c	 	 	 
$DGUV-Grau:	#555555     // <18pt: AAA (weiß)
$Grau-3: #706f6f        // <18pt: AA (weiß) ; >18pt: AAA (weiß), AA (schwarz)	 
$Grau-4: #878787        // <18pt: AA (schwarz) ; >18pt: AAA (schwarz), AA (weiß)	 	 	 
$DGUV-Hellgrau:	#9c9c9c //	<18pt: AAA (schwarz)
$Grau-6: #b2b2b2	 	 	 
$Grau-7: #c6c6c6	 	 	 
$Grau-8: #dadada	 	 	 
$Grau-9: #ededed	 	 	 

// Türkis
$Tuerkis-D: #00434b	 	 	 
$Tuerkis-C: #00525a	 	 	 
$Tuerkis-B: #006169     // <18pt: AAA (weiß) 	 	 
$Tuerkis-A: #007c84     // <18pt: AA (weiß) ; >18pt: AAA (weiß), AA (schwarz)	 	 	 
$DGUV-Tuerkis: #008c8e  // <18pt: AA (schwarz) ; >18pt: AAA (schwarz)
$Tuerkis-2: #02979d
$Tuerkis-3: #2ea1a1     // >18pt: AA (weiß)	 
$Tuerkis-4: #42abad     // <18pt: AAA (schwarz)	 	 
$Tuerkis-5: #54b6ba	 	 	 
$Tuerkis-6: #67bcc4	 	 	 
$Tuerkis-7: #88cdd3	 	 	 
$Tuerkis-8: #a4d8de	 	 	 
$Tuerkis-9: #d6eceb	 	 	 
$Tuerkis-10: #ebf6f6	 	 	 

// Grün
$Gruen-D: #006226       // <18pt: AAA (weiß) 	 	 
$Gruen-C: #00712a       // >18pt: AA (schwarz)	 	 
$Gruen-B: #00812c       // <18pt: AA (weiß) ; >18pt: AAA (weiß)	 	 	 
$Gruen-A: #2f912e       // <18pt: AA (schwarz) ; >18pt: AAA (schwarz) 	 	 
$DGUV-Gruen: #4ca22f    // >18pt: AA (weiß)
$Gruen-2: #62a72b       // <18pt: AA (schwarz)
$Gruen-3: #74ac26	 	 	 
$Gruen-4: #8bb920	 	 	 
$Gruen-5: #9abe18	 	 	 
$DGUV-Hellgruen: #afca0b 
$Gruen-7: #bbd151	 	 	 
$Gruen-8: #d1de84	 	 	 
$Gruen-9: #d9e6b1	 	 	 
$Gruen-1: #ecf3da	 	 	 

// Orange
$Orange-D: #b55103        // <18pt: AA (wei0) ; >18pt: AA (schwarz), AAA (weiß)
$Orange-C: #cb6c00      // <18pt: AA (schwarz) ; >18pt: AAA (schwarz) 
$Orange-B: #dd7500      // >18pt: AA (weiß)
$Orange-A: #e88300      // <18pt: AAA (schwarz)
$DGUV-Orange: #f39200
$Orange-2: #f7a600
$Orange-3: #fbba00
$DGUV-Gelb: #ffcc00
$Orange-5: #ffdd00
$Orange-6: #ffed00
$Orange-7: #fff042
$Orange-8: #fff482
$Orange-9: #fff7b2
$Orange-10: #fffbdc

// Rot
$Rot-D: #681c16	 	 	 
$Rot-C: #801d18	 	 	 
$Rot-B: #9b1c18         // <18pt: AAA (weiß) 	 
$Rot-A: #b71918         // >18pt: AA (schwarz)
$DGUV-Rot: #d51317      // >18pt: AAA (weiß)
$Rot-2: #d73534         // <18pt: AA (weiß)	 	 	 
$Rot-3: #ea5045         // <18pt: AA (schwarz) ; >18pt: AAA (schwarz)
$Rot: #ed6b6a           // >18pt: AA (weiß)
$Rot-5: #f08482         // <18pt: AAA (schwarz)
$Rot-6: #f39b9b
$Rot-7: #f6b2b6
$Rot-8: #f8c7c8
$Rot-9: #fbdbdb
$Rot-10: #fdeded

// Violett
$Violett-D: #56184a
$Violett-C: #6e1955
$Violett-B: #881861
$Violett-A: #a3156c     // <18pt: AAA (weiß) 
$DGUV-Violett: #b81178  // >18pt: AA (schwarz)
$Violett-2:	#c1348b
$Violett-3: #c24191     // <18pt: AA (wei0) ; >18pt: AAA (weiß)
$Violett-4: #cb5d9f     // <18pt: AA (schwarz) ; >18pt: AAA (schwarz)
$Violett-5: #d56aa6     // >18pt: AA (weiß) 
$Violett-6: #d682b5     // <18pt: AAA (schwarz)
$Violett-7: #e19ac3
$Violett-8: #e3afd1
$Violett-9: #efc6df
$Violett-10: #f1d9ea