@import '../../scss/variables.scss';

.navRow {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $light;
    box-shadow: 0px -3px 0.5rem 1px rgba(60, 60, 60, 0.5);
    padding: 0.5rem 0;
    z-index: 100;

    .container {
        padding: 1rem 0;
    }

    p {
        margin: 0;
    }

    .topicCol {
        display: none;
        flex-direction: column;
        justify-content: center;
        
        @media(min-width: 768px){
            display: flex;
        }
    }

    .topic {
        font-family: $font-family-bold;
        color: $primary;
        font-size: 1.5rem;
        margin-top: 1rem;
    }

    .questions {
        color: $primary;
        font-size: 1.25rem
    }

    .buttonRow {
        margin: 0 !important;
    }

}