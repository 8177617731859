@import '../../scss/variables.scss';

footer{
    margin: 2rem 0;


    .footerCol {
        max-width: 600px;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        flex-flow: wrap;

        ul {
            padding: 0;
            list-style-type: none;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            flex-wrap: wrap;

            li {
                margin: 0;
                padding: 0;
            }
        }
    }

    a.footerLink{
        color: $primary;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: calc(0.8rem + 0.3vw);
        margin: 0.5rem;
        transition: 500ms;
        &:hover{
            text-decoration: underline;
            transition: 500ms;
        }
        @media (max-width: 576px){
            padding: 0 1rem;
        }
        @media (max-width: 767px){
            min-height: 48px;
        }
        @media (min-width: 1400px){
            margin-right: 1rem;
        }
    }
}