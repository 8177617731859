
// Custom css

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    &.bg-blue {
        background-color: $Blau-10;
    }
}

h1 {
    font-family: $font-family-bold;
    color: $primary;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    
    @media(min-width: 768px){
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
}

h2 {
    color: $primary;
    font-size: 1.15rem;
    margin-bottom: 0.5rem;

    @media(min-width: 768px){
        font-size: 1.4rem;
    }
}

h3 {
    font-family: $font-family-bold;
    color: $primary;
    font-size: 1rem;

    @media(min-width: 768px){
        font-size: 1.3rem;
    }
}

h4 {
    font-family: $font-family-bold;
    color: $dark;
    font-size: 1rem;

    @media(min-width: 768px){
        font-size: 1.2rem;
    }
}

h5 {
    font-family: $font-family-bold;
    color: $dark;
    font-size: 1rem;
}

button, button.btn, a.btn {
    font-family: $font-family-bold;
    font-size: 1.1rem;
    
    @media(max-width: 576px){
        font-size: 1rem;
    }
}

a.active-link {
    font-family: $font-family-bold;
    text-decoration: underline;
}

@keyframes rotation {
    from {
      transform: rotate(0deg);
      transform-origin: center;
      transform-style: preserve-3D;
    }
    to {
        transform: rotate(359deg);
        transform-origin: center;
        transform-style: preserve-3D;
    }
}

.loading-animation {
    min-height: 70vh;
}

.spinner-icon{
    animation: spin 2s infinite;
    width: 50px;
    height: 50px;
    position: relative;
    top: 10vh;
    left: calc( 50% - 25px );
    color: $primary;
}

@keyframes spin{
    from{ 
        transform: rotate(0deg)
    }
    to{
        transform: rotate(359deg)
    }
}

.sr-only {
    font-size: 0;
}

figure {
    width: 100%;
    height: auto;

    video {
        width: 100%;
        height: auto;
    }
}