@import '../../scss/variables.scss';

.buttons {
    display: flex;
    align-items: center;
    margin: 2rem 0 1.5rem;
    flex-wrap: wrap;

    @media(min-width: 576px){
        flex-wrap: nowrap;
    }

    button, a {
        font-size: calc(1.2rem + 0.5vw);
        font-family: $font-family-bold;
        min-width: calc(100px + 3vw);
        min-height: calc(48px + 0.5vw);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    span {
        font-size: calc(1rem + 0.5vw);
        font-family: $font-family-bold;
        color: $secondary;

        @media(max-width: 992px){
            display: none;
        }
    }
}