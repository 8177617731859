@import '../../scss/variables.scss';

.navWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    
    @media(min-width: 992px){
        position: relative;
        width: auto;
    }

    .navigationOverlay {
        display: flex;
        position: fixed;
        top: -100%;
        left: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 20vh;
        background-color: $light;
        box-shadow: -1px 1px 3px $dark;
        z-index: 100;
        transition: 500ms;
        padding: 0.5rem;
        margin: 0;
        list-style-type: none;

        li {
            width: auto;
            margin: 0;
            padding: 0;
        }

        @media(min-width: 992px){
            display: none;
        }

        @media(min-width: 1400px){
            display: flex;
            position: relative;
            top: unset;
            bottom: unset;
            left: unset;
            flex-direction: row;
            justify-content: flex-start;
            min-height: auto;
            box-shadow: none;
            background-color: unset;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary;
            padding: 0.5rem 1rem;
            min-height: 48px;
            width: 100%;
            
            &:hover {
                background-color: $Grau-9;
                color: $dark;
            }
        }

        .closeMenuBtn {
            display: none;
        }

        .footerLink {
            display: none;
            color: $secondary;
            font-size: 0.95rem;
            min-height: 36px;
            text-decoration: none;
        }

        &.active {
            position: fixed;
            top: 0;
            transition: 500ms;

            li {
                width: 100%;
            }

            .closeMenuBtn {
                display: flex;
                margin: auto;
            }

            @media(min-width: 992px){
                display: flex;
                position: absolute;
                top: calc(100% - 12px);
                bottom: unset;
                min-width: 200px;
                width: 25vw;
                min-height: auto;
                border-radius: 0.25rem;
                box-shadow: 1px 1px 3px $dark;
                overflow: hidden;

                a {
                    justify-content: center;
                    width: calc(100%);
                    justify-content: flex-start;
                }

                .closeMenuBtn {
                    display: none;
                }
            }

            @media(min-width: 1400px){
                display: flex;
                position: relative;
                top: unset;
                bottom: unset;
                left: unset;
                flex-direction: row;
                justify-content: flex-start;
                min-height: auto;
                box-shadow: none;
                background-color: unset;
                width: 100%;
    
                a {
                    margin-right: 1rem;
                    width: auto;

                    &:hover {
                        // background-color: unset;
                        color: $primary;
                        text-decoration: underline;
                    }
    
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .footerLink {
                display: flex;
            }
            
        }

        @media(min-width: 1400px){
            display: flex;
            position: relative;
            width: auto;

            a {
                margin-right: 1rem;
                width: auto;
                min-width: 150px;

                &:hover {
                    background-color: unset;
                    color: $primary;
                    text-decoration: underline;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            li {
                width: auto !important;
            }

            .footerLink {
                display: none !important;
            }
        }

    }

    .menuBtn {
        &.active {
            background-color: $primary;

            svg {
                color: $light !important;
                path {
                    fill: $light;
                }
            }
        }
        @media(min-width: 1400px){
            display: none;
        }
    }
}