@import '../../scss/variables.scss';

.header {
    display: flex;
    margin-bottom: 1rem;
    // margin: 1rem 0 2rem;

    @media (min-width: 768px){
        margin: 1rem 0;
    }

    .headerRow {
        flex-wrap: nowrap;
        width: 100%;
        margin: 0;
    }

    @media print {
        display: none;
    }
}

.schlafometerSlogan {
    display: flex;
    align-items: center;
    margin-right: 2vw !important;
    text-decoration: none;

    @media (min-width: 768px){
        margin-right: 2rem !important;
    }

    
    .smTitle {
        display: flex;
        flex-direction: column;
        font-size: 1.1rem;
        font-family: $font-family-bold;
        min-width: 150px;
        
        @media (max-width: 575px){
            display: none;
        }
    }

    .smIcon {
        width: 100%;
        min-width: 32px;
        max-width: 48px;
        height: auto;
        margin-right: calc(1rem + 0.5vw);
    }

    .smSlogan {
        font-size: 1rem;
        font-family: $font-family-base;
        display: none;

        @media (min-width: 768px){
            display: flex;
        }
    }
}